import SiteWrapper from "../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../assets/images/pagerduty_dxable_og.png";
import { useEffect } from "react";

import MdRenderer from "../components/mdrender";
import * as md from "../components/mdrender.module.sass";
import TitleBlock from "../components/title";

import "./free-trial-2@gen.scss";

const GeneratedFreeTrial2 = () => {
  useEffect(() => {
    var sc, d;
    try {
      sc = document.createElement("script");
      sc.src =
        "https://digitalstackscorporation-team.myfreshworks.com/crm/sales/web_forms/bb5cfd25388637772c9e9980a85f37b5f301f5f76b8f42a1444eb66c514f16f2/form.js";
      sc.crossOrigin = "anonymous";
      sc.id =
        "fs_bb5cfd25388637772c9e9980a85f37b5f301f5f76b8f42a1444eb66c514f16f2";
      d = document.getElementById("contact-form");
      d.appendChild(sc);

      new MutationObserver(function (mutationsList, observer) {
        mutationsList.forEach((mutation) => {
          if (mutation.addedNodes) {
            mutation.addedNodes.forEach((node) => {
              var css = node.querySelector
                ? node.querySelector('link[href*="webforms.css"]')
                : null;
              if (css) {
                observer.disconnect();
                css.addEventListener("load", function () {
                  setTimeout(function () {
                    loop: for (
                      var i = 0;
                      i < document.styleSheets.length;
                      i++
                    ) {
                      var styleSheet = document.styleSheets[i];
                      if (
                        styleSheet.href &&
                        styleSheet.href.includes("webforms.css")
                      ) {
                        var rules = styleSheet.rules;
                        for (var j = 0; j < rules.length; j++) {
                          var rule = rules[j];
                          if (
                            rule.selectorText &&
                            rule.selectorText.includes(".hidden,")
                          ) {
                            var cssText = rule.cssText.replace(
                              ".hidden,",
                              ".fs-webform-container .hidden,"
                            );
                            styleSheet.deleteRule(j);
                            styleSheet.insertRule(cssText, j);
                            break loop;
                          }
                        }
                      }
                    }
                  });
                });
              }
            });
          }
        });
      }).observe(document.body, { childList: true, subtree: true });
    } catch (e) {}

    return () => {
      if (sc && d) d.removeChild(sc);
    };
  }, []);

  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>PagerDuty無料トライアル - PagerDuty正規代理店 DXable</title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="究極のインシデント管理ツール 無料トライアルで全機能を14日間お試し可能"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="究極のインシデント管理ツール 無料トライアルで全機能を14日間お試し可能"
        />

        <meta
          property="og:title"
          content="PagerDuty無料トライアル - PagerDuty正規代理店 DXable"
        />

        <meta
          property="og:description"
          content="究極のインシデント管理ツール 無料トライアルで全機能を14日間お試し可能"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/free-trial-2/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/free-trial-2/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Free Trial"
        textGreen="PagerDutyの無料トライアルの申し込み"
        overview=""
      />

      <div className="freetrialWrapper">
        <div className="center-1 !mt-0 mb-[20px]">
          <h2 className="center-title">
            究極のインシデント管理ツール
            <br /> 無料トライアルで全機能を14日間お試し可能
          </h2>
          <p className="center-para flex-col">
            <span className="block mb-[80px]">
              トライアル期間中は、ユーザーの追加、削除は自由に行え、
              PagerDutyのフル機能をお試しいただけます。
              <br />
              製品版への移行の際は、設定はそのまま引き継ぐこともできます。
            </span>
            <span>
              ご利用のインターネット環境によりフォームが表示されない場合がございます。
              <br />
              もしも、入力フォームが表示されない場合は、こちらのメールアドレスへお問い合わせ
              ください。
              <br />
              info@digitalstacks.net
              <span className="block text-[red]">
                *この項目は入力する必要があります。
              </span>
            </span>
          </p>
        </div>
      </div>

      <div id="contact-form" className="formWrapper mt-[-10px] mb-[40px]"></div>

      <div className="freetrialWrapper">
        <div className="center-1 !mt-0 mb-[20px]">
          <p>
            <a
              target="_blank"
              href="https://www.pagerduty.com/terms-of-service/"
              className="text-[#4EB346]"
            >
              PagerDutyサービス利⽤規約（英⽂）
            </a>
            および
            <a
              target="_blank"
              href="https://corp.digitalstacks.net/privacy-policy"
              className="text-[#4EB346]"
            >
              個人情報保護方針
            </a>
            についてご確認の上、ご同意いただける場合は、「トライアルご注意事項に同意する」の項目にチェックを入れてご送信ください。
          </p>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedFreeTrial2;
